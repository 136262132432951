import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form'
import { Row, Col, Form, Table, Input, Card, CardHeader, CardBody, Button } from 'reactstrap'
import { toast } from 'react-toastify';

const Product = ({ currentOrder, currentSegment, setCurrentSegment, jumpToStep }) => {

    const { handleSubmit } = useForm();
    const selectedSegment = currentOrder.segments.find((segment) => segment._id === currentSegment);

    let myTotalAmountTotal = 0;
    let myTotalDiscount = 0;
    let addTotalDiscount = 0;
    let totalCopaymentDiscount = 0;
    selectedSegment.products.forEach((product) => {
        let myProcessDiscount = (100 - parseFloat(product.discount)  ) / 100;
        myTotalAmountTotal += ( product.quantity * parseFloat(product.pvf) * myProcessDiscount );
        myTotalDiscount += ( product.quantity * parseFloat(product.pvf) * (parseFloat(product.discount )/100) );

        addTotalDiscount += ( product.quantity * parseFloat(product.pvf) * (parseFloat(product.phAdditionalDiscount || 0)/100) );
        totalCopaymentDiscount += ( ( product.quantity * parseFloat(product.pvf) * myProcessDiscount ) * (parseFloat( selectedSegment.copaymentPh || 0) / 100) );
    });

    const [totalAmount, setTotalAmount] = useState(myTotalAmountTotal);
    const [totalDiscount, setTotalDiscount] = useState(myTotalDiscount);
    const [addDiscount, setAddDiscount] = useState(addTotalDiscount);
    const [copaymentDiscount, setCopaymentDiscount] = useState(totalCopaymentDiscount);

    // eslint-disable-next-line no-unused-vars
    const onChange = () => {
        let total = 0;
        selectedSegment.products.forEach((product) => {
            total += parseInt(product.quantity) * product 
            setTotalAmount(total);


            // if (product.quantity) {
            //     if (myProcessDiscount) {
            //         totalRow = (product.quantity * parseFloat(product.pvf) * myProcessDiscount);
            //     } else {
            //         totalRow = (product.quantity * parseFloat(product.pvf));
            //     }
            // } else {
            //     if (myProcessDiscount) {
            //         totalRow = (1 * parseFloat(product.pvf) * myProcessDiscount);
            //     } else {
            //         totalRow = (1 * parseFloat(product.pvf));
            //     }
            // }
        });
    }
    const onSubmit = data => {
        let orderValid = true;
        let myTotalQuantity = 0;
		let myTotalAmountTotal = 0;
        selectedSegment.products.forEach((product) => {
            myTotalQuantity += parseInt(product.quantity);
            let myProcessDiscount = (100 - parseFloat(product.discount)  ) / 100;
			myTotalAmountTotal += (product.quantity * parseFloat(product.pvf) * myProcessDiscount );
            if (product.minimumOrder && product.stepFactor) {
                if (parseInt(product.quantity) < product.minimumOrder) {
                    toastNotif({
                        title: `Produto "${product.name}" Inválido`,
                        text: `Quantidade mínima por CNP não satisfeita. Mínimo ${product.minimumOrder}`,
                    });
                    product.valid = false;
                } else if (parseInt(product.quantity) % product.stepFactor !== 0) {
                    toastNotif({
                        title: `Produto "${product.name}" Inválido`,
                        text: `Múltiplos de unidades não satisfeita. Múltiplo de ${product.stepFactor}`,
                    });
                    product.valid = false;
                } else {
                    product.valid = true;
                }
            } else {
                product.valid = true;
            }
        });

        if(myTotalQuantity < selectedSegment.minimumQuantity ){
            orderValid = false;
            toastNotif({
                title: `Pedido Inválido`,
                text: `Quantidade de unidades total inferior ao mínimo estabelecido para este segmento : ${selectedSegment.minimumQuantity}`,
            });
        }

        if(myTotalAmountTotal < selectedSegment.minimumOrder ){
            orderValid = false;
            toastNotif({
                title: `Pedido Inválido`,
                text: `Quantidade total inferior ao mínimo estabelecido para este segmento : ${selectedSegment.minimumOrder}`,
            });
        }
        if (orderValid && selectedSegment.products.filter((product) => !product.valid).length === 0) {
            jumpToStep(2);
        }
    };

    const toastNotif = ({ title, text }) => {
        toast.error(<><span>{title}</span><br /><span>{text}</span></>, {
            position: toast.POSITION.BOTTOM_CENTER
        });
    };

    let button = document.getElementById("next-button");
    if (button) button.style.display = 'none';

    return (
        <Fragment>
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-4">
                    <Col sm="12">
                        <Table className="mb-4">
                            <thead>
                                <tr>
                                    <th scope="col">CNP</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">PVF</th>
                                    <th scope="col">Desconto</th>
                                    <th scope="col">Desc. Ad.</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Mín</th>
                                    <th scope="col">Múlt</th>
                                    <th scope="col">Valor Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSegment.products.map((product, index) => {
                                    let myProcessDiscount = (100 - parseFloat(product.discount)) / 100;
                                    let totalRow = 0

                                    if (product.quantity) {
                                        if (myProcessDiscount) {
                                            totalRow = (product.quantity * parseFloat(product.pvf) * myProcessDiscount);
                                        } else {
                                            totalRow = (product.quantity * parseFloat(product.pvf));
                                        }
                                    } else {
                                        if (myProcessDiscount) {
                                            totalRow = (1 * parseFloat(product.pvf) * myProcessDiscount);
                                        } else {
                                            totalRow = (1 * parseFloat(product.pvf));
                                        }
                                    }

                                    return (
                                        <tr key={index}>
                                            <th scope="row">{product.cnp}</th>
                                            <td>{product.name}</td>
                                            <td>{product.pvf.toFixed(2)} €</td>
                                            <td>{product.discount ? product.discount : 0} %</td>
                                            <td>{product.phAdditionalDiscount ? product.phAdditionalDiscount : 0} %</td>
                                            <td>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    name="product_z_quantity"
                                                    step={product.stepFactor}
                                                    min={0}
                                                    value={product.quantity}
                                                    onChange={(ev) => {
                                                        const productEdited = selectedSegment.products.find((leProduct) => product.cnp === leProduct.cnp)
                                                        productEdited.quantity = ev.target.value;
                                                        const newSegment = { ...selectedSegment }
                                                        setCurrentSegment(newSegment._id);

                                                        let myTotalAmountTotal = 0;
                                                        let myTotalDiscount = 0;
                                                        let addTotalDiscount = 0;
                                                        let totalCopaymentDiscount = 0;
                                            
                                                        selectedSegment.products.forEach((product) => {
                                                            let myProcessDiscount = (100 - parseFloat(product.discount)  ) / 100;
                                                            myTotalAmountTotal += ( product.quantity * parseFloat(product.pvf) * myProcessDiscount );
                                                            myTotalDiscount += ( product.quantity * parseFloat(product.pvf) * (parseFloat(product.discount)/100) );

                                                            addTotalDiscount += ( product.quantity * parseFloat(product.pvf) * (parseFloat(product.phAdditionalDiscount|| 0)/100) );
                                                            totalCopaymentDiscount += ( ( product.quantity * parseFloat(product.pvf) * myProcessDiscount ) * (parseFloat( selectedSegment.copaymentPh || 0) / 100) );
                                                        });
                                                        setTotalAmount(myTotalAmountTotal);
                                                        setTotalDiscount(myTotalDiscount);
                                                        setAddDiscount(addTotalDiscount);
                                                        setCopaymentDiscount(totalCopaymentDiscount);
                                                    }}
                                                />
                                            </td>
                                            <td>{product.minimumOrder ? product.minimumOrder : '-'}</td>
                                            <td>{product.stepFactor ? product.stepFactor : '-'}</td>
                                            <td>{totalRow.toFixed(2)} €</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm="3">
                            <Card>
                                <CardHeader>
                                    <h5>Total encomenda</h5>
                                </CardHeader>
                                <CardBody>
                                    <h4 className="mb-0 counter">{totalAmount.toFixed(2)} €</h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card>
                                <CardHeader>
                                    <h5>Desconto</h5>
                                </CardHeader>
                                <CardBody>
                                    <h4 className="mb-0 counter">{totalDiscount.toFixed(2)} €</h4>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="3">
                            <Card>
                                <CardHeader>
                                    <h5>Desconto Adic.</h5>
                                </CardHeader>
                                <CardBody>
                                    <h4 className="mb-0 counter">{addTotalDiscount.toFixed(2)} €</h4>
                                </CardBody>
                            </Card>
                        </Col>
                        { currentOrder.inq_type !== 'direct' && (
                            <Col sm="3">
                                <Card>
                                    <CardHeader>
                                        <h5>Copagamento</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <h4 className="mb-0 counter">{copaymentDiscount.toFixed(2)} €</h4>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                </Row>
                <Button color="primary" className="pull-right" onClick={onSubmit}>{"Seguinte"}</Button>
            </Form>
        </Fragment>
    );
};

export default Product;